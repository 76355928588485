<template>
    <v-card elevation="1" outlined class="mb-4" style="max-width:1800px;">
        <v-card-text class="justify-center custom-debugx">
            <!-- Edit Controls -->
            <div v-if="isViewingSelf() && showAdvanced" class="row py-1 px-0" style="background-color: #eeeeee; display:flex; justify-content: right;">
                <div class="col col-4 col-sm-3 py-0" style="display:flex;">
                    <div class="col col-4 py-0 my-0">
                        <ListSettingsModal :listId=list.list_id :handleSuccess=refreshList />
                    </div>
                    <div class="col col-4 py-0">
                        <CustomIcon @click="cloneList(list.list_id)" toolTip="Copy" glyph="mdi-content-copy" /> 
                    </div>
                    <div class="col col-4 py-0">
                        <CustomIcon @click="deleteList(list.list_id, list.item_count)" toolTip="Delete" color="#cc4444" glyph="mdi-delete" /> 
                        <ConfirmDialog ref="confirm" />
                    </div>
                </div>
            </div>


            <!-- View Panel -->
            <div class="row">
                <div class="col col-12 py-1 mt-1" style="display: flex; justify-content: left; align-items: center; overflow: hidden;">
                    <ListIcon v-if="list.list_icon_slug" :listIconSlug="list.list_icon_slug" class="mr-2" size="small" />
                    <h2> <a :href="'/' + list.channel_name + '/' + list.list_name">{{list.list_name}}</a> </h2>
                </div>
            </div>
            <div class="row pb-1">
                <div class="col col-4 py-1">
                    <h4>by <a :href="'/' + list.channel_name">{{list.channel_name}}</a></h4>
                    <div class="py-1 ">
                        <h6> Last Updated: {{displayTimeAgo(list.last_activity_date)}} </h6>
                    </div>
                </div>
                <v-spacer />
                <div class="py-1 mt-2">
                    <div class="row">
                        <div class="col col-6 py-1 label" style=""> Views: </div>
                        <div class="col col-6 py-1 value"> {{list.view_count}} </div>
                    </div>
                    <div class="row">
                        <div class="col col-6 py-1 label"> Items: </div>
                        <div class="col col-6 py-1 value"> {{list.item_count}} </div>
                    </div>
                    <div class="row">
                        <div class="col col-6 py-1 label" v-if="isViewingSelf() && this.showAdvanced"> Public Access: </div>
                        <div class="col col-6 py-1 value" v-if="isViewingSelf() && this.showAdvanced"> {{list.public_access}} </div>
                    </div>
                    <div class="row">
                        <div class="col col-6 py-1 label" v-if="isViewingSelf() && this.showAdvanced"> Friend Access: </div>
                        <div class="col col-6 py-1 value" v-if="isViewingSelf() && this.showAdvanced"> {{list.friend_access}} </div>
                    </div>
                </div>
            </div>
            <div class="row" v-if="list.tag_count > 0">
                <div class="col col-12 d-none d-sm-flex py-1" style="height: 100%;">
                    <TagViewer :listId="list.list_id" />
                </div>
            </div>
        </v-card-text>
    </v-card>
</template>
<script>
import api from '/src/utils/api';
import auth from "/src/utils/auth";
import timeAgo from '/src/utils/timeAgo';

export default {
    components: {
        ConfirmDialog: () => import("/src/components/ConfirmDialog"),
        CustomIcon: () => import("/src/components/CustomIcon.vue"),
        ListIcon: () => import("/src/components/ListIcon"),
        ListSettingsModal: () => import("/src/components/ListSettingsModal"),
        TagViewer: () => import("/src/components/TagViewer.vue"),
    },
    data() {
        return {
            isViewingSelf: auth.isViewingSelf,
            showAdvanced: false,
        };
    },
    mounted() {
        this.showAdvanced = this.advancedMode || false;
    },
    methods: {
        displayTimeAgo(dbTime) {
            return timeAgo(dbTime);
        },
        cloneList(listId) {
            api.postAuthRequired('clone-list', {listId}).then(res => {
                this.refreshList(true);
            });
        },
        async deleteList(listId, itemCount) {
            var confirmDelete = true;
            if(itemCount > 0)
                confirmDelete = await this.$refs.confirm.open("Delete List", "Are you sure you want to delete this record?");

            if ( confirmDelete ) {
                api.postAuthRequired('delete-list', {listId}).then(res => {
                    this.refreshList();
                });
            }
        },
    },
    props: ['list', 'advancedMode', 'refreshList'],
};
</script>

<style scoped>
.label {
    display: flex;
    justify-content: right;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
    white-space: nowrap;
}
.value {
    display: flex;
    justify-content: left;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
}
</style>